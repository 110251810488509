import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../rootSlice";
import { nextStep } from "../stepSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { submitForm } from "../rootSlice";
import { setEBToken } from "../ebSlice";
import dkaCertificate from "../img/dka_zertifikate_pzhkueffel.png";
import apImage from "../img/clara_maschmann.jpg";

const schema = Yup.object().shape({
  salutation: Yup.string().required().typeError("Pflichtfeld"),
  firstname: Yup.string().nullable(),
  lastname: Yup.string().required("Pflichtfeld"),
  email: Yup.string()
    .email("Bitte eine gültige E-Mail-Adresse eingeben")
    .required("Pflichtfeld"),
  phoneNumber: Yup.string()
    .required("Pflichtfeld")
    .matches(/^\d+$/, "Bitte nur Zahlen verwenden")
    .min(3, "Min. 3 Ziffern"),
  icZipcode: Yup.string()
    .min(5, "Postleitzahl muss mind. 5 Ziffern lang sein")
    .max(5, "Postleitzahl darf max. 5 Ziffern lang sein")
    .matches(/^\d+$/, "Die Postleitzahl darf nur Zahlen enthalten")
    .required("Bitte geben Sie Ihre Postleitzahl an"),
  currentUrl: Yup.string().nullable(),
  privacy: Yup.boolean().oneOf([true], "Pflichtfeld").required("Pflichtfeld"),
  icPersonsCare: Yup.string().required("Wieviele Personen sind zu betreuen?"),
  icStart: Yup.string()
    .required("Bitte wählen Sie eine Option aus")
    .typeError("Bitte wählen Sie eine Option aus"),
});

const Question1 = () => {
  const dispatch = useDispatch();
  const icZipcode = useSelector((state) => state.form.form.icZipcode);
  const icStart = useSelector((state) => state.form.form.icStart);
  // eslint-disable-next-line
  const [submitError, setSubmitError] = useState(false);
  const [disable, setDisable] = useState(false);
  const icPersonsCare = useSelector((state) => state.form.form.icPersonsCare);

  useEffect(() => {
    const browserUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    document.getElementById("currentUrl").value = browserUrl;
  }, []);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: { icZipcode, icPersonsCare, icStart },
  });

  const onSubmit = (data) => {
    if (isValid) {
      let premium = process.env.REACT_APP_PREMIUM;
      dispatch(
        setForm({
          salutation: data.salutation,
          phoneNumber: data.phoneNumber,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          privacy: !!data.privacy,
          icZipcode: data.icZipcode,
          icPersonsCare: data.icPersonsCare,
          icStart: data.icStart,
          currentUrl:
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname,
          isPremium: premium,
        }),
        setDisable(true)
      );
      dispatch(submitForm())
        .then((res) => {
          console.warn(res.payload);
          if (res.payload.token) {
            dispatch(setEBToken(res.payload.token));
            dispatch(nextStep());
          }
        })
        .catch(() => {
          setSubmitError(true);
          setDisable(false);
        });
    }
  };

  return (
    <div className="ka-step ka-step--1 ka-step--active">
      <div className="ka-person-trust">
        <div className="trust-headline">
          Jetzt unverbindliches Angebot erhalten!
        </div>
        <div className="trust-container">
          <div className="trust-container--left">
            <img
              className="trust-ap-image"
              src={apImage}
              alt="Clara Maschmann"
            />
          </div>
          <div className="trust-container--right">
            <img
              className="trust-certificate-image"
              src={dkaCertificate}
              alt="DKA Zertfikat"
            />
          </div>
        </div>
        <div className="trust-subheadline">
          Clara Maschmann / Neukundenberatung
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="ka-form__fieldwrap with-top-border">
          Anrede*
          <input
            type="radio"
            id="female"
            name="gender"
            value="female"
            className="ka-form__radio"
            {...register("salutation")}
          />
          <label htmlFor="female">Frau</label>
          <input
            type="radio"
            id="male"
            name="gender"
            value="male"
            className="ka-form__radio"
            {...register("salutation")}
          />
          <label htmlFor="male">Herr</label>
          <input
            type="radio"
            id="unknown"
            name="gender"
            value="unknown"
            className="ka-form__radio"
            {...register("salutation")}
          />
          <label htmlFor="unknown">Divers</label>
          {errors.salutation && (
            <p className={"error"}>{errors.salutation.message}</p>
          )}
        </div>

        <div className="ka-form__grid">
          <div className="ka-form__fieldwrap ka-form__fieldwrap--half">
            <input
              placeholder="Vorname"
              type="text"
              name="firstname"
              id="firstname"
              className={
                errors.firstname
                  ? " ka-form__input has-error"
                  : "ka-form__input"
              }
              {...register("firstname")}
            />
            {errors.firstname && (
              <p className={"error"}>{errors.firstname.message}</p>
            )}
          </div>
          <div className="ka-form__fieldwrap ka-form__fieldwrap--half">
            <input
              placeholder="Nachname*"
              type="text"
              name="lastname"
              id="lastname"
              className={
                errors.lastname ? " ka-form__input has-error" : "ka-form__input"
              }
              {...register("lastname")}
            />
            {errors.lastname && (
              <p className={"error"}>{errors.lastname.message}</p>
            )}
          </div>
        </div>

        <div className="ka-form__grid">
          <div className="ka-form__fieldwrap ka-form__fieldwrap--half">
            <input
              placeholder="Telefonnummer*"
              type="phoneNumber"
              name="phoneNumber"
              id="phoneNumber"
              className={
                errors.phoneNumber
                  ? " ka-form__input has-error"
                  : "ka-form__input"
              }
              {...register("phoneNumber")}
            />
            {errors.phoneNumber && (
              <p className={"error"}>{errors.phoneNumber.message}</p>
            )}
          </div>
          <div className="ka-form__fieldwrap ka-form__fieldwrap--half">
            <input
              placeholder="E-Mail-Adresse*"
              type="email"
              name="email"
              id="email"
              className={
                errors.email ? " ka-form__input has-error" : "ka-form__input"
              }
              {...register("email")}
            />
            {errors.email && <p className={"error"}>{errors.email.message}</p>}
          </div>
        </div>
        <div className="ka-form__grid">
          <div className="ka-form__fieldwrap">
            <div className="ka-step__dropdown">
              <select
                name="q3-a2"
                id="q3-a2"
                className={
                  errors.icPersonsCare
                    ? " ka-ka-input--select has-error"
                    : "ka-ka-input--select"
                }
                {...register("icPersonsCare")}
              >
                <option value="">Zu betreuende Personen?</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="more">Mehr als 2</option>
              </select>
            </div>
          </div>
        </div>
        {errors.icPersonsCare && (
          <p className={"error"}>{errors.icPersonsCare.message}</p>
        )}
        <div className="ka-form__grid">
          <div className="ka-form__fieldwrap">
            <div className="ka-step__dropdown">
              <select
                name="q1-a4"
                id="q3-a2"
                className={
                  errors.icStart
                    ? " ka-ka-input--select has-error"
                    : "ka-ka-input--select"
                }
                {...register("icStart")}
              >
                <option value="">Beginn der Unterstützung?</option>
                <option value="soon">Schnellstmöglich</option>
                <option value="weeks">In ca. 3–4 Wochen</option>
                <option value="months">In ca. 2–3 Monaten</option>
                <option value="unclear">Das ist noch unklar</option>
              </select>
            </div>
          </div>
        </div>
        {errors.icStart && <p className={"error"}>{errors.icStart.message}</p>}

        <div className="ka-form__grid">
          <div className="ka-form__fieldwrap">
            <input
              placeholder="PLZ des Einsatzortes"
              type="text"
              name="q2-q2"
              id="q2-a2"
              className={
                errors.icZipcode
                  ? " ka-form__input has-error"
                  : "ka-form__input"
              }
              {...register("icZipcode")}
            />
            {errors.icZipcode && (
              <p className={"error"}>{errors.icZipcode.message}</p>
            )}
          </div>
        </div>
        <div className="ka-form__grid">
          <div className="ka-form__fieldwrap">
            <input
              type="hidden"
              id="currentUrl"
              name="currentUrl"
              {...register("currentUrl")}
            />
          </div>
        </div>
        <h4>Einwilligung zum Datenschutz*</h4>
        <div>
          <input
            type="checkbox"
            name="privacy"
            id="privacy"
            {...register("privacy")}
          />
          <label htmlFor="privacy">
            Die gem. Art. 13 DSGVO zur Verfügung zu stellenden Informationen zum{" "}
            <a
              href="https://www.pflegezuhause.info/de/anfrage/datenschutz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz
            </a>
            &nbsp;wurden mir mitgeteilt.
          </label>
        </div>

        {/*STEP BUTTONS*/}
        <button
          type="submit"
          className="ka-button ka-button__next-step if_disabled"
          disabled={disable}
        >
          <span>{disable ? "Bitte warten … " : "Absenden"}</span>
        </button>
      </form>
    </div>
  );
};

export default Question1;
